// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../components/SEO'
import RewardsReportContainer from '../../../containers/RewardsReportContainer'

import LayoutMain from '../../../layout'

const Rewards: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Bonificaciones" />
      <WithPrivateRoute component={RewardsReportContainer} />
    </LayoutMain>
  )
}

export default Rewards
