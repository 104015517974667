// Package
import styled from 'styled-components'
import { Collapse } from 'antd'

const { Panel } = Collapse

export const ReturnButtonContent = styled.div`
  margin: 12px 0;
  display: flex;
`

export const DisplayPointsContent = styled.div`
  float: right;

  @media (min-width: 320px) {
    width: 150px;
  }

  @media (min-width: 360px) {
    width: 175px;
  }
`

export const RewardsReportConent = styled.div`
  padding: 12px 16px 12px 12px;
  .ant-collapse {
    background-color: #f0f2f5 !important;
    border: none !important;

    .ant-collapse-header {
      color: #fff !important;
    }

    .ant-collapse-content-box {
      background-color: #f7f7f7;
      @media (min-width: 320px) {
        padding: 8px;
      }
      @media (min-width: 420px) {
        padding: 16px;
      }
    }
  }

  .customPrimaryButton {
    width: 125px;
    height: 48px;
    display: block;
    margin-left: auto;
  }
`

export const StyledPanel = styled(Panel)`
  background-color: #0b4793;
  font-size: 14px;
  border-radius: 6px !important;
  margin-bottom: 12px;
  .ant-collapse-header .ant-collapse-header-text {
    width: 100%;
  }
`

export const StyledContentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const StyledTitleHeader = styled.div`
  float: left;
`

export const StyledTotalHeader = styled.div`
  font-weight: bold;
  float: right;
`
export const DetailedPointsContent = styled.div`
  width: 100%;
  display: block;
  padding: 12px;
  height: 45px;
  border-radius: 23px;
  background-color: #fff;
  margin: 0 auto;
  font-weight: bold;
  align-items: center;
  margin-bottom: 12px;
`
export const DetailPointsTitle = styled.div`
  color: #000;
  margin-left: 12px;
  display: contents;

  @media (min-width: 320px) {
    font-size: 10px;
  }

  @media (min-width: 360px) {
    font-size: 12px;
  }
`
export const DetailPointsCount = styled.span`
  color: #0b4793;
  float: left;

  @media (min-width: 320px) {
    font-size: 12px;
  }

  @media (min-width: 360px) {
    font-size: 14px;
  }
`
export const DetailPointsTotal = styled.span`
  color: #0b4793;
  float: right;

  @media (min-width: 320px) {
    font-size: 12px;
  }

  @media (min-width: 360px) {
    font-size: 14px;
  }
`
export const TotalPointsContent = styled.div`
  display: block;
`

export const TotalTitle = styled.span`
  margin-left: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #434343;
`

export const TotalPointsPanelFooter = styled.div`
  display: flow-root;
`

export const TotalPointsSummaryDisplay = styled.div`
  float: left;
  &:last-child {
    float: right;
  }
  & > span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    width: 125px;
    height: 38px;
    border-radius: 19px;
    background-color: #0b4793;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > small {
    font-family: Montserrat;
    font-size: 12px;
    text-align: center;
    color: #434343;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const TotalPointsDislaimer = styled.small`
  font-size: 8px;
  font-weight: normal;
  color: #a2a2a2;
  margin-top: 14px;
`
export const NoResultsContent = styled.div`
  width: 100%;
  text-align: center;
  img {
    margin-top: 70px;
    margin-bottom: 20px;
    width: 150px;
  }
  h1 {
    color: #828282;
    margin-bottom: 50px;
    font-size: 16px;
  }
`
export const StyledRewardsButtonBack = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;

  #button-icon-back-sales-report {
    margin: 12px 0 0 24px;
  }

  h1 {
    width: 230px;
    margin: 14px 9px 0 9px;
  }
`
