// Packages
import React from 'react'

// Styled Components
import { NoResultsContent } from './styledComponents'

// Assets
import moneyBag from '../../data/assets/images/moneyBag.png'

const EmptyReport: React.FC = () => {
  return (
    <>
      <NoResultsContent>
        <img src={moneyBag} alt="moneyBag" />
        <h1>Tus bonificaciones se verán reflejadas al final del mes</h1>
      </NoResultsContent>
    </>
  )
}

export default EmptyReport
