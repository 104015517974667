// Packages
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { LeftOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

// Styled Components
import {
  ReturnButtonContent,
  RewardsReportConent,
  StyledPanel,
  StyledContentHeader,
  StyledTitleHeader,
  StyledTotalHeader,
  TotalPointsContent,
  TotalTitle,
  TotalPointsPanelFooter,
  TotalPointsSummaryDisplay,
  TotalPointsDislaimer
} from './styledComponents'
import { StyledReportTitle } from '../FilterSalesReportContainer/styledComponents'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'

// Components
import CustomButton from '../../components/Storybook/customButton'
import DetailedPoints from './detailedPoints'
import EmptyReport from './emptyReport'

// Actions
import { requestDataRewards } from '../../redux/ducks/rewardsGraphicsReport/actionsCreators'

// Utils
import {
  convertNumberToMoney,
  filterUniqueWithValue,
  parseIntMonthToString,
  truncateNumber
} from '../../utils/general'
import { transformQueryRewardReportToDataComponents } from './utils'
import { getParameter } from '../../utils/queryString'
import { customEventGA } from '../../utils/googleAnalitycs'

// Components
import Loading from '../../components/Shared/Loading'

// Types
import { reducersTypes } from '../../redux/reducers'
import { RewardMonthReport } from '../../types/Reports/ReportRewards'
import { StoresSelect } from '../../components/StoresSelect'
import { callProfileRedux } from '../ProfileContainer'
import { removeSelect } from '../../components/StoresSelect/utils'
import moment from 'moment'
import { findIndex } from 'lodash'

const RewardsReportContainer: React.FC = () => {
  let rewardReport: RewardMonthReport[] = []
  let typeOfPointsStore = 'Rewards'
  let isMawEnable = false

  const month = moment().format('DD/MM/YYYY').split('/')[1]
  const year = new Date().getFullYear().toString()
  const storeID = getParameter('storeId')
  const { user_data } = useSelector(callProfileRedux)
  const { rewards_data, isLoading } = useSelector(
    (state: reducersTypes) => state.rewards_report
  )
  const [collapsePanelActiveKey, setCollapsePanelActiveKey] = useState('0')
  const dispatch = useDispatch()

  useEffect(() => {
    if (storeID) {
      dispatch(requestDataRewards({ storeID: storeID, month, year }))
    }
  }, [storeID])

  if (!isLoading && rewards_data.getBonusReport.length > 2) {
    const { getBonusReport: tileRowTransformed } =
      transformQueryRewardReportToDataComponents(rewards_data)
    rewardReport = tileRowTransformed
    typeOfPointsStore = filterUniqueWithValue(
      rewardReport.map(({ typeOfTotalPoints }) => typeOfTotalPoints)
    )[0] as string
  }

  if (user_data && user_data.getProfile && storeID) {
    const findIndexPerStoreID = findIndex(
      user_data.getProfile.stores,
      (val: any) => val.storeID === storeID
    )
    const getMawEnableValue =
      findIndexPerStoreID !== -1
        ? user_data.getProfile.stores[findIndexPerStoreID].storeType.mawEnable
        : 0

    isMawEnable = getMawEnableValue === 0 ? false : true
  }

  const headerContent = (dataMonth: any) => {
    return (
      <StyledContentHeader>
        <StyledTitleHeader>
          {parseIntMonthToString(dataMonth.month)}- {dataMonth.year}{' '}
        </StyledTitleHeader>

        <StyledTotalHeader>
          {typeOfPointsStore === 'Cartones'
            ? convertNumberToMoney(dataMonth.totalPointsAsMonetaryAmount)
            : truncateNumber(dataMonth.totalPoints, 2)}
        </StyledTotalHeader>
      </StyledContentHeader>
    )
  }

  const collapseCallback = (key: string | string[]): void => {
    const activeKey: string = key[1]
      ? key[1].toString()
      : collapsePanelActiveKey
    setCollapsePanelActiveKey(activeKey)
  }

  const onChangeStore = (value: string): void => {
    removeSelect()
    navigate(`/reports/rewards?storeId=${value}`)
  }

  const body = (
    <>
      <StoresSelect
        store={storeID ? storeID : user_data.getProfile?.stores[0].storeID}
        stores={user_data.getProfile?.stores}
        onChange={onChangeStore}
      />
      <ReturnButtonContent>
        <CustomButton
          id="button-icon-back-sales-report"
          name="buttonIconBackSalesReport"
          className="button-icon-back-sales-report"
          dataTestId="data-test-button-icon-back-sales-report"
          icon={<LeftOutlined />}
          onClick={() => navigate('/reports')}
        />
        <StyledReportTitle>Reportes</StyledReportTitle>
      </ReturnButtonContent>
      <RewardsReportConent>
        {rewards_data.getBonusReport.length < 2 && <EmptyReport />}
        <Collapse
          defaultActiveKey={['0']}
          expandIconPosition="right"
          activeKey={collapsePanelActiveKey}
          onChange={collapseCallback}
        >
          {rewardReport?.map((rewardReport, index) => {
            const isRewards = typeOfPointsStore === 'Rewards'
            return (
              <StyledPanel header={headerContent(rewardReport)} key={index}>
                {rewardReport.totalPointsAsMonetaryAmount > 0 ? (
                  <DetailedPoints
                    {...rewardReport}
                    isRewards={isRewards}
                    isMawEnable={isMawEnable}
                  />
                ) : (
                  <EmptyReport />
                )}

                <TotalPointsContent>
                  <TotalTitle hidden={!isRewards}>Total:</TotalTitle>
                  <TotalPointsPanelFooter>
                    <TotalPointsSummaryDisplay hidden={!isRewards}>
                      <span>{truncateNumber(rewardReport.totalPoints, 2)}</span>
                      <small>
                        {isRewards ? 'Puntos BEES' : typeOfPointsStore}
                      </small>
                    </TotalPointsSummaryDisplay>
                    <TotalPointsSummaryDisplay>
                      <span>
                        {convertNumberToMoney(
                          rewardReport.totalPointsAsMonetaryAmount
                        )}
                      </span>
                      <small>Monto en pesos</small>
                    </TotalPointsSummaryDisplay>
                  </TotalPointsPanelFooter>
                  {typeOfPointsStore !== 'Rewards' && (
                    <TotalPointsDislaimer>
                      * Los cartones enviados serán lo mas cercano a su
                      equivalente al monto total de pesos
                    </TotalPointsDislaimer>
                  )}
                </TotalPointsContent>
              </StyledPanel>
            )
          })}
        </Collapse>
        <StyledButtonPrimary
          className="customPrimaryButton"
          type="button"
          onClick={() => {
            navigate(`/reports/rewards/graphics?storeId=${storeID}`)
            customEventGA({
              eventAction: 'button_graphic_dashboard',
              eventCategory: 'graphics',
              eventLabel: 'report_rewards'
            })
          }}
          disabled={rewards_data.getBonusReport.length < 2}
        >
          DASHBOARD
        </StyledButtonPrimary>
      </RewardsReportConent>
    </>
  )

  return (
    <>
      {!isLoading && body}
      {isLoading && <Loading data-testid="icon-loading" />}
    </>
  )
}

export default RewardsReportContainer
