// Packages
import React from 'react'

// Styled Components
import {
  DetailedPointsContent,
  DetailPointsTitle,
  DisplayPointsContent,
  DetailPointsCount,
  DetailPointsTotal
} from './styledComponents'

// Components
import { convertNumberToMoney, truncateNumber } from '../../utils/general'

// Mocks
import { RewardMonthReport } from '../../types/Reports/ReportRewards'

type Props = {
  isMawEnable: boolean
}

const DetailedPoints: React.FC<RewardMonthReport & Props> = (
  rewardMonthReportProps
) => {
  const setSuffix = rewardMonthReportProps.isRewards ? 'pt' : 'ct'

  return (
    <>
      <DetailedPointsContent>
        <DetailPointsTitle>Modelo para ganar</DetailPointsTitle>
        <DisplayPointsContent>
          <DetailPointsTotal>
            {convertNumberToMoney(rewardMonthReportProps.mpgMonetaryAmount)}
          </DetailPointsTotal>
          <DetailPointsCount
            hidden={!rewardMonthReportProps.isRewards}
          >{`${truncateNumber(
            rewardMonthReportProps.mpgPoints,
            2
          )}${setSuffix}`}</DetailPointsCount>
        </DisplayPointsContent>
      </DetailedPointsContent>

      <DetailedPointsContent>
        <DetailPointsTitle>Loyalty</DetailPointsTitle>
        <DisplayPointsContent>
          <DetailPointsCount
            hidden={!rewardMonthReportProps.isRewards}
          >{`${truncateNumber(
            rewardMonthReportProps.loyaltyPoints,
            2
          )}${setSuffix}`}</DetailPointsCount>
          <DetailPointsTotal>
            {convertNumberToMoney(rewardMonthReportProps.loyaltyMonetaryAmount)}
          </DetailPointsTotal>
        </DisplayPointsContent>
      </DetailedPointsContent>

      <DetailedPointsContent>
        <DetailPointsTitle>Promociones</DetailPointsTitle>
        <DisplayPointsContent>
          <DetailPointsCount
            hidden={!rewardMonthReportProps.isRewards}
          >{`${truncateNumber(
            rewardMonthReportProps.promotionsPoints,
            2
          )}${setSuffix}`}</DetailPointsCount>
          <DetailPointsTotal>
            {convertNumberToMoney(
              rewardMonthReportProps.promotionsMonetaryAmount
            )}
          </DetailPointsTotal>
        </DisplayPointsContent>
      </DetailedPointsContent>

      {rewardMonthReportProps.frioPoints > 0 && (
        <DetailedPointsContent>
          <DetailPointsTitle>Frío</DetailPointsTitle>
          <DisplayPointsContent>
            <DetailPointsCount
              hidden={!rewardMonthReportProps.isRewards}
            >{`${truncateNumber(
              rewardMonthReportProps.frioPoints,
              2
            )}${setSuffix}`}</DetailPointsCount>
            <DetailPointsTotal>
              {convertNumberToMoney(rewardMonthReportProps.frioMonetaryAmount)}
            </DetailPointsTotal>
          </DisplayPointsContent>
        </DetailedPointsContent>
      )}

      {rewardMonthReportProps.posUsabilityPoints > 0 && (
        <DetailedPointsContent>
          <DetailPointsTitle>Usabilidad POS</DetailPointsTitle>
          <DisplayPointsContent>
            <DetailPointsCount
              hidden={!rewardMonthReportProps.isRewards}
            >{`${truncateNumber(
              rewardMonthReportProps.posUsabilityPoints,
              2
            )}${setSuffix}`}</DetailPointsCount>
            <DetailPointsTotal>
              {convertNumberToMoney(
                rewardMonthReportProps.posUsabilityMonetaryAmount
              )}
            </DetailPointsTotal>
          </DisplayPointsContent>
        </DetailedPointsContent>
      )}
      {rewardMonthReportProps.isMawEnable &&
        rewardMonthReportProps.beeruPoints > 0 && (
          <DetailedPointsContent>
            <DetailPointsTitle>Beer U</DetailPointsTitle>
            <DisplayPointsContent>
              <DetailPointsCount
                hidden={!rewardMonthReportProps.isRewards}
              >{`${truncateNumber(
                rewardMonthReportProps.beeruPoints,
                2
              )}${setSuffix}`}</DetailPointsCount>
              <DetailPointsTotal>
                {convertNumberToMoney(
                  rewardMonthReportProps.beeruMonetaryAmount
                )}
              </DetailPointsTotal>
            </DisplayPointsContent>
          </DetailedPointsContent>
        )}
    </>
  )
}

export default DetailedPoints
